<template>
  <div class="vld-parent">
    <loading v-model:active="toggleLoading"
      :can-cancel="false"
      :is-full-page="true"/>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: {
    Loading
  },
  setup() {
    const store = useStore();
    const toggleLoading = computed(
      () => store.getters['NotificationStore/toggleLoading']
    );

    return {
      toggleLoading,
    }
  },
}
</script>

<style>
.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid rgb(45,212,191);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
export const SET_MESSAGE = "setMessage";
export const SET_SNACKBAR_FALSE = "SET_SNACKBAR_FALSE";
export const SET_OPEN_MODAL = "SET_OPEN_MODAL";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const SET_MODAL_MESSAGE = "SET_MODAL_MESSAGE";
export const SET_MODAL_TITLE = "SET_MODAL_TITLE";
export const SET_MODAL_BUTTONS = "SET_MODAL_BUTTONS";

export default {
  SET_MESSAGE,
  SET_SNACKBAR_FALSE,
  SET_OPEN_MODAL,
  SET_MODAL_TITLE,
  SET_MODAL_MESSAGE,
  SET_MODAL_BUTTONS,
  TOGGLE_LOADING,
};

<template>
  <Header />
  <div class="body">
    <router-view />
    <Modal />
    <toast />
  </div>
  <Footer />
  <loading />
</template>

<script>
import Header from "@/components/Layouts/Header.vue";
import Footer from "@/components/Layouts/Footer.vue";
import Toast from "./components/Shared/Toast.vue";
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import Web3 from 'web3';
import Fortmatic from 'fortmatic';
import { useRouter } from 'vue-router';
import Modal from './components/Modals/Modal.vue';
import Loading from './components/Shared/Loading.vue';


export default {
  components: {
    Header,
    Footer,
    Toast,
    Modal,
    Loading,
  },
    Loadingtup() {
    
    const store = useStore();
    const router = useRouter();
 
    onMounted(async () => {
      const fm = new Fortmatic('pk_test_74DE84F478732B84');
      await store.dispatch('blockchain/getBlockChain');
      //const address = store.getters['blockchain/getCurrentAddress'];
      const provider = store.getters['blockchain/getCurrentProvider'];
      
      /* const unsubscribe = store.subscribe(async (mutation) => {
        console.log(mutation);
        if (mutation.type == 'blockchain/setProvider') {
          const address = store.getters['blockchain/getCurrentAddress'];
          const provider = store.getters['blockchain/getCurrentProvider'];
          console.log(address);
          console.log(provider);
          await store.dispatch('user/getUser', {address: address, provider: provider});
        }

        if (mutation.type == 'user/setUser') {
          unsubscribe();

        }
      }); */

      if (provider == 'metamask') {
        window.ethereum.on('accountsChanged', () => {
          store.dispatch('user/logoutUser');
          router.push("/");
        });

      } else if (provider == 'fortmatic') {
        if (window.ethereum) {
            // Use MetaMask provider
            window.web3 = new Web3(window.ethereum);
          } else {
            // Use Fortmatic provider
           

            window.web3 = new Web3(fm.getProvider());
          }
      }
    })
    
  }
};
</script>

<style scoped>
.body {
  min-height: calc(100vh - 400px);
  position: relative;
}
</style>


import Fortmatic from 'fortmatic';
import Portis from '@portis/web3';
import { get, set, del } from 'idb-keyval';

// Works for web3 1.0 and pre-1.0 versions
import Web3 from 'web3';

const state = {
    currentProvider: null,
    currentAddress: null,
    transaction: {

    },
    connected: false
};

// getters
const getters = {
    getCurrentProvider: (state) => {
        return state.currentProvider;
    },
    getCurrentAddress: (state) => {
        return state.currentAddress;
    },
    isConnected: (state) => {
        return state.connected
    }
}

// mutations
const mutations = {
    setProvider (state, payload) {
        console.log(payload);
        state.currentProvider = payload;
    },
    setCurrentAddress(state, payload) {
        state.currentAddress = payload;
    },
    setConnected(state, payload) {
        state.connected = payload;
    }
  }


const actions = {
    async connected({commit}) {

        const fortmatic = new Fortmatic('pk_test_74DE84F478732B84', 'ropsten');
        const portis = new Portis('a8b095a1-4da6-49ab-8073-9a8bddaf8a85', 'ropsten');
        let currentAddress = null;

        if (window.ethereum) {
            // Use MetaMask provider
            window.web3 = new Web3(window.ethereum);
            const addresses = await window.ethereum.request({ method: 'eth_requestAccounts' });
            currentAddress = addresses[0];
            
        } else {
            
            let isUserLoggedIn = await fortmatic.user.isLoggedIn();
            if (isUserLoggedIn) {
                window.web3 = new Web3(fortmatic.getProvider());
            } else {
                const { error } = await portis.isLoggedIn();
                if (!error) {
                    window.web3 = new Web3(portis.provider);
                }
            }

            const addresses = await window.web3.eth.getAccounts();  
            currentAddress = addresses[0];
        }

        if (currentAddress) {
            commit('setConnected', true);
        } else {
            commit('setConnected', false);
        }
        
    },
    async getBlockChain ({commit, dispatch}) {


        const fortmatic = new Fortmatic('pk_test_74DE84F478732B84', 'ropsten');
        const portis = new Portis('a8b095a1-4da6-49ab-8073-9a8bddaf8a85', 'ropsten');
        const bString = await get('blockchain');
        let currentAddress = null;

        if (bString) {

            const data = JSON.parse(bString);

            if (window.ethereum) {
                // Use MetaMask provider
                window.web3 = new Web3(window.ethereum);
                const addresses = await window.ethereum.request({ method: 'eth_requestAccounts' });
                currentAddress = addresses[0];
                
            } else {
                
                let isUserLoggedIn = await fortmatic.user.isLoggedIn();
                if (isUserLoggedIn) {
                    window.web3 = new Web3(fortmatic.getProvider());
                } else {
                    const { error } = await portis.isLoggedIn();
                    if (!error) {
                        window.web3 = new Web3(portis.provider);
                    }
                }

                const addresses = await window.web3.eth.getAccounts();  
                currentAddress = addresses[0];
            }

            if (currentAddress == data.address) {
                commit('setCurrentAddress', data.address);
                commit('setProvider', data.provider);
            } else {
                await del('blockchain');
                commit('setProvider', null);
                commit('setCurrentAddress', null)
                dispatch('user/delete', {}, {root:true})
            }
        }

    },
    async new ({ commit, dispatch }, payload) {

        let address = '';

        if (payload.type == 'fortmatic') { 
            
            const fortmatic = new Fortmatic('pk_test_74DE84F478732B84', 'ropsten');
            window.web3 = new Web3(fortmatic.getProvider());

            await fortmatic.configure({ primaryLoginOption: 'phone' });
            let isUserLoggedIn = await fortmatic.user.isLoggedIn();
            if (!isUserLoggedIn) {
                await fortmatic.user.login();
            }

            address = await window.web3.eth.getAccounts();            
            
        } else if(payload.type == 'portis') {

            const portis = new Portis('a8b095a1-4da6-49ab-8073-9a8bddaf8a85', 'ropsten');
            window.web3 = new Web3(portis.provider);
            //commit('setProvider', web3);
            address = await window.web3.eth.getAccounts();
            
        } else {
            address = await window.ethereum.request({ method: 'eth_requestAccounts' });

        }

        commit('setCurrentAddress', address[0]);
        commit('setProvider', payload.type);

        dispatch('user/getUser', {address: address[0], provider: payload.type}, {root: true});

        const data = {
            provider: payload.type,
            address: address[0]
        }

        await set('blockchain', JSON.stringify(data));
        
    },
    async delete({commit}) {
        await del('blockchain');
        commit('setProvider', null);
        commit('setCurrentAddress', null)
    }
  }

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
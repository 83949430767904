import { get, set, del } from "idb-keyval";
import { db, getClientByBlockChain } from "../../firebase/firebase";

// initial state
const state = () => ({
  user: {
    dbRef: "",
    username: "",
    about: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    newsletter_push: false,
    email_push: false,
    sms_push: false,
    avatar: null,
    metamask: "",
    fortmatic: "",
    portis: "",
    nfts: [],
  },
});

const getDefaultState = () => {
  return {
    user: {
      dbRef: "",
      username: "",
      about: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      comments: false,
      candidates: false,
      offers: false,
      smsEverything: false,
      smsSameEmail: false,
      smsNotifications: false,
      avatar: null,
      metamask: "",
      fortmatic: "",
      portis: "",
      nfts: [],
    },
  };
};

// getters
const getters = {
  getMetamask: (state) => {
    return state.user.metamask;
  },
  getFortmatic: (state) => {
    return state.user.fortmatic;
  },
  getPortis: (state) => {
    return state.user.portis;
  },
  getUser: (state) => {
    return state.user;
  },
  getFullName: (state) => {
    console.log(state);
    return state.user.firstName + " " + state.user.lastName;
  },
  getAvatar: (state) => {
    return state.user.avatar;
  },
  getEmail: (state) => {
    return state.user.email;
  },
};

// actions
const actions = {
  async getUser({ commit, dispatch }, payload) {
    const user = await get("user");
    if (user) {
      commit("setUser", JSON.parse(user));
    } else {
      if (payload.provider) {
        dispatch("recoverUser", payload);
      }
      
    }
  },
  
  async recoverUser({ commit }, payload) {
    const results = await db
      .collection("clients")
      .where(payload.provider, "==", payload.address.toLowerCase())
      .get();
    if (!results.empty) {
      const user = results.docs[0].data();
      user.dbRef = results.docs[0].id;
      console.log(user);
      await set("user", JSON.stringify(user));
      commit("setUser", user);
    }
  },
  async saveUser({ commit }, payload) {
    const clientRef = payload.username + "_" + payload.email;
    await db.collection("clients").doc(clientRef).set(payload);

    let user = payload;
    user.dbRef = clientRef;
    await set("user", JSON.stringify(user));
    commit("setUser", user);
  },
  async addNft({commit}, payload) {
    const results = await getClientByBlockChain(payload.address.toLowerCase());
    console.log(results);
    if (results.state) {
      const user = results.data;
      user.dbRef = results.dbRef;
      console.log(user);
      const nfts = user.nfts;
      nfts.push(payload.nft);

      await db.collection("clients").doc(user.dbRef).update({nfts: nfts});

      commit('updateNfts', nfts);
    }
  },
  async logoutUser({ commit, dispatch }) {
    await del("user");
    commit("resetState");
    //block chain action
    dispatch("blockchain/delete", {}, {root: true});
  },
  async delete({ commit }) {
    await del("user");
    commit("resetState");
  },
};

// mutations
const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  updateNfts(state, payload) {
    state.user.nfts = payload;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="home bg-gray-100 font-inter">
    <div class="relative  py-16 sm:py-24 lg:py-16">
        <div class="relative">
          <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <p class="mt-2 text-4xl font-medium leading-10 text-black tracking-tight sm:text-4xl">
              Artists Collections
            </p>
          </div>
          <ArtistCollection :src="'home'" />
        </div>
      </div>
  </div>
</template>

<script>
import ArtistCollection from '@/components/ArtistCollection.vue'

// @ is an alias to /src
 /* const collections = [
  {
    id: 1,
    title: 'Mario Sanchez',
    href: '/artist',
    items: '7',
    imageUrl:
      '/images/art-2.jpg',
  },
  {
    id: 2,
    title: 'Michael Marrero',
    href: '/artist',
    items: '10',
    imageUrl:
      '/images/art-1.jpg',
     
  },
   
] */

export default {
  components: {
    ArtistCollection
  },
  setup() {
    return {
    }
  },
}
</script>

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/frontend/Home.vue";
import adminGuard from "../guards/AdminGuard";
import clientGuard from "../guards/ClientGuard";
// import { useStore } from "vuex";

// const store = useStore();

const routes = [
  // Frontend
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/claim",
    name: "Claim",

    component: () => import("../views/frontend/Claim.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",

    component: () => import("../views/frontend/Privacy.vue"),
  },
  {
    path: "/terms",
    name: "Terms",

    component: () => import("../views/frontend/Terms.vue"),
  },
  {
    path: "/swap",
    name: "Swap",

    component: () => import("../views/frontend/Swap.vue"),
  },
  {
    path: "/contact",
    name: "Contact",

    component: () => import("../views/frontend/Contact.vue"),
  },
  {
    path: "/artist/:ref",
    name: "Artist",
    component: () =>
      import(/* webpackChunkName: "artist" */ "../views/frontend/Artist.vue"),
  },
  {
    path: "/artwork/:collection/:ref",
    name: "Artwork",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/frontend/Artwork.vue"),
  },
  {
    path: "/series",
    name: "Series",
    component: () => import("../views/frontend/Series.vue"),
  },
  {
    path: "/client-page/:username",
    name: "ClientPage",
    component: () => import("../views/frontend/Client.vue"),
  },

  //Client
  {
    path: "/register/:address",
    name: "ClientRegister",
    component: () => import("../views/client/Register.vue"),
  },
  {
    path: "/my-account/:address",
    name: "MyAccount",
    component: () => import("../views/client/Myaccount.vue"),
    beforeEnter: clientGuard,
  },
  {
    path: "/my-collections",
    name: "My_collections",
    component: () => import("../views/client/Mycollections.vue"),
    beforeEnter: clientGuard,
  },
  {
    path: "/my-collection",
    name: "My_collection",
    component: () => import("../views/client/Mycollection.vue"),
    beforeEnter: clientGuard,
  },
  {
    path: "/my-wallets",
    name: "My_wallets",
    component: () => import("../views/client/Mywallets.vue"),
    beforeEnter: clientGuard,
  },
  {
    path: "/my-bids",
    name: "My_bids",
    component: () => import("../views/client/Mybids.vue"),
    beforeEnter: clientGuard,
  },

  //Admin
  {
    path: "/admin/login",
    name: "Login",
    component: () => import("../views/admin/Login.vue"),
  },
  {
    path: "/admin/my-account",
    name: "AdminMyAccount",
    component: () => import("../views/admin/Myaccount.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/admin/my-wallet",
    name: "AdminMyWallet",
    component: () => import("../views/admin/Mywallets.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/admin/collections",
    name: "Admin_collections",
    component: () => import("../views/admin/Collections.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/admin/register/:uuid",
    name: "Register",
    component: () => import("../views/admin/Register.vue"),
  },
  {
    path: "/admin/collection/detail/:ref",
    name: "Collection_detail",
    component: () => import("../views/admin/Collectiondetail.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/admin/settings",
    name: "Admin_settings",
    component: () => import("../views/admin/Settings"),
    beforeEnter: adminGuard,
  },
  {
    path: "/admin/artwork/:collection/:artid?",
    name: "Artist_detail",
    component: () => import("../views/admin/Artistdetail.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/admin/profile/:username",
    name: "admins_profile",
    component: () => import("../views/admin/Profile.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/admin/user-profile/:username",
    name: "clients_profile",
    component: () => import("../views/admin/Profile.vue"),
    beforeEnter: adminGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {

//   const adminAuth  = store.state.adminStore.auth.authAdmin;
//   const clientAuth  = store.state.adminStore.auth.clientAuth;

//   if (to.matched.some(record => record.meta.requiresAuth == "admin") && adminAuth) {
//     next()
//   } else if (to.matched.some(record => record.meta.requiresAuth == "client") && clientAuth){
//     next()
//   }else{
//       next('/')
//   }
// })
export default router;
